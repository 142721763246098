import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import NavBar from "../components/NabBar";
import Footer from "../components/Footer";
import Project from "../pages/Projects";
import { Service } from "../pages/Services";
const AppRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<WithNav element={<About />} />} />
          <Route path="/contact" element={<WithNav element={<Contact />} />} />
          <Route
            path="/services/:segment"
            element={<WithNav element={<Service />} />}
          />
          <Route path="/projects" element={<WithNav element={<Project />} />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;

const WithNav = ({ element }) => {
  const location = useLocation();
  const UserRenderNavbar = location.pathname !== "/";
  return UserRenderNavbar ? (
    <>
      <NavBar />
      {element}
    </>
  ) : (
    element
  );
};
