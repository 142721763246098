import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./homeNav.css";
import { pages } from "../../Seed";
import { ServiceScreenNav } from "../NabBar/serviceScreen/index";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [serviceOpen, setServiceOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleServiceEnter = () => {
    console.log("Service menu opened"); // Debug log
    setServiceOpen(true);
  };

  const handleServiceLeave = () => {
    console.log("Service menu closed"); // Debug log
    setServiceOpen(false);
  };

  return (
    <AppBar position="static" className="Home-nav">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <Link to="/">
            <img src={logo} alt="name" className="logo" />
          </Link> */}

          {/* For mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon className="menuIcon" />
            </IconButton>
            <Menu
              className="menu"
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages[0].map((pageName, index) => (
                <Link
                  to={pages[1][index]}
                  key={index}
                  onClick={handleCloseNavMenu}
                  className="nav-link secondaryColor link m-10"
                >
                  <Typography textAlign="center">{pageName}</Typography>
                </Link>
              ))}
            </Menu>
          </Box>

          {/* For desktop menu */}
          <Box
            className="NavBarPageLinkSingle"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {pages[0].map((pageName, index) =>
              pageName === "Services" ? (
                <Box
                  key={index}
                  className="link secondaryColor nav-link"
                  onMouseEnter={handleServiceEnter}
                  onMouseLeave={handleServiceLeave}
                  sx={{ position: "relative" }} // Positioning for dropdown
                >
                  <Link className="ServiceLinkInNav clr-white">{pageName}</Link>
                  {serviceOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                        border: "1px solid #aaa",
                        borderRadius: "15px",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        padding: "10px",
                        minWidth: "150px",
                      }}
                    >
                      <ServiceScreenNav />
                    </Box>
                  )}
                </Box>
              ) : (
                <Link
                  to={pages[1][index]}
                  key={index}
                  onClick={handleCloseNavMenu}
                  className="link  nav-link clr-white"
                >
                  {pageName}
                </Link>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
