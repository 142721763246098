// Importing the icons for services
import Web from "./assets/icons/web.png";
import SEO from "./assets/icons/SEO.png";
import UI from "./assets/icons/UI.png";
import Apps from "./assets/icons/app.png";
// Setting the company name
let CompanyName = "AsrNova";

// Defining pages for the website
export let pages = [
  ["Home", "About", "Projects", "Services", "Contact"],
  ["/", "/about", "/projects", "/", "/contact"],
];
export let footerpages = [
  ["Home", "About", "Projects", "Contact"],
  ["/", "/about", "/projects", "/contact"],
];
// Defining services offered by ByteSmith
export let services = [
  {
    title: "Web Development",
    description: `${CompanyName} crafts responsive, scalable, and secure websites tailored to your business needs.`,
    img: Web,
  },
  {
    title: "SEO Optimization",
    description: `${CompanyName} boosts your online presence with advanced SEO strategies.`,
    img: SEO,
  },
  {
    title: "UI-UX",
    description: `${CompanyName} enhances user experience with innovative UI/UX design services.`,
    img: UI,
  },
  {
    title: "Mobile Apps",
    description: `${CompanyName} brings your ideas to life with custom mobile apps.`,
    img: Apps,
  },
];

// Detailed information about services for the Services Page
export let servicesPage = [
  {
    title: "Web Development",
    description: `At ${CompanyName}, we craft responsive, scalable, and secure websites tailored to your business needs. Our team of expert developers ensures that your website not only looks great but also performs seamlessly across all devices. Whether you need a corporate website, an e-commerce platform, or a dynamic web application, we’ve got you covered.`,
    whyUneedTitle: "Why you need",
    whyNeeded: `A well-designed website is your digital storefront. It impacts user engagement, sales conversions, and search engine rankings. Without a properly optimized website, your business could be losing potential customers to competitors. A modern, responsive, and fast website builds credibility, improves user experience, and keeps visitors coming back. In today's digital age, a solid online presence is a key driver of business growth and success.`,
    benefits: [
      "Fully customized design tailored to reflect your brand’s identity, ensuring a strong online presence.",
      "Fast-loading, SEO-optimized websites built with performance in mind, improving both speed and user satisfaction.",
      "Developed using the latest cutting-edge technologies such as React, Node.js, and WordPress, ensuring long-term reliability and scalability.",
      "Responsive design across all devices, from desktops to mobile, offering a seamless experience for all users.",
      "Comprehensive ongoing support and maintenance services to ensure your website stays updated and secure.",
      "Clearly defined project deliverables with no hidden costs, ensuring transparency throughout the development process.",
      "Flexible, scalable solutions designed to grow with your business and adapt to changing market needs.",
      "Robust security protocols to safeguard your website against cyber threats and unauthorized access.",
      "Integration with third-party tools for enhanced functionalities like CRMs, payment gateways, and more.",
      "Customized user dashboards and content management systems to simplify daily operations.",
      "Detailed performance analytics to help track user behavior, conversions, and engagement.",
    ],
    tools: [
      "React.js",
      "Next.js",
      "Node.js",
      "Tailwind CSS",
      "Bootstrap",
      "MUI",
      "MERN",
    ],
  },
  {
    title: "SEO Optimization",
    description: `${CompanyName} boosts your online presence with advanced SEO strategies designed to improve your search engine rankings, drive organic traffic, and increase conversions. Our comprehensive SEO services include on-page optimization, keyword research, content marketing, and technical SEO.`,
    whyUneedTitle: "Why you need",
    whyNeeded: `With over 90% of online experiences beginning with a search engine, optimizing your website for search engines is crucial for visibility and growth. Without SEO, your site may remain buried in search results, limiting your potential to attract new customers. SEO helps improve your site’s credibility, ensures it ranks higher on Google and other search engines, and ultimately drives more organic traffic to your website.`,
    benefits: [
      "Increase in website traffic by targeting the most relevant keywords that match user intent.",
      "Significantly higher rankings on Google and other search engines, giving your business increased visibility.",
      "Enhanced user experience through faster page load times and improved site architecture.",
      "Development of long-term, sustainable strategies for continued growth in organic traffic over time.",
      "Comprehensive website audits to identify technical and on-page SEO improvements.",
      "Competitor analysis to stay ahead of industry rivals in search rankings and traffic.",
      "Monthly performance reports highlighting keyword performance, traffic growth, and actionable insights.",
      "Tailored SEO strategies aligned with your industry’s needs and specific business goals.",
      "Local SEO optimization to help businesses attract customers in nearby areas.",
      "Enhanced mobile SEO to ensure mobile users have a smooth experience on your site.",
      "Content creation and marketing strategies that build credibility and authority in your industry.",
    ],
    tools: ["Google Analytics", "Ahrefs", "SEMrush", "Moz"],
  },
  {
    title: "UI-UX",
    description: `${CompanyName} enhances user experience with innovative UI/UX design services. We focus on creating intuitive, engaging, and visually appealing interfaces that keep users coming back. Our design team works closely with you to align the visuals with your brand's identity and user needs.`,
    whyUneedTitle: "Why you need",
    whyNeeded: `Good UI/UX design is more than just aesthetics; it’s about creating a positive and engaging experience for your users. A poorly designed interface can frustrate users, leading to higher bounce rates and lost business opportunities. By investing in professional UI/UX design, you ensure that your digital platforms are easy to use, visually appealing, and aligned with user expectations, enhancing customer satisfaction and loyalty.`,
    benefits: [
      "User-centered design focusing on delivering a seamless experience that keeps users engaged.",
      "Intuitive and easy-to-navigate interfaces that guide users through your platform without confusion.",
      "Cross-platform designs that work seamlessly on web, mobile, and desktop, enhancing accessibility.",
      "Tailored design solutions crafted specifically to align with your brand’s vision and goals.",
      "Responsive design that adjusts to different devices and screen sizes, providing an optimized experience.",
      "Comprehensive usability testing and feedback collection to ensure the design is meeting user needs.",
      "Clear and structured design process with constant communication and collaboration with clients.",
      "Ongoing post-launch support to continually refine and improve designs based on real user feedback.",
      "Creation of interactive prototypes for testing ideas before full development, saving time and resources.",
      "Information architecture that organizes content in a way that makes it easy to find and navigate.",
      "Focus on micro-interactions to create a delightful user experience with attention to detail.",
    ],
    tools: ["Figma", "Adobe XD", "Sketch", "InVision"],
  },
  {
    title: "Mobile Apps",
    description: `${CompanyName} brings your ideas to life with custom mobile apps tailored to your business goals. Whether you're looking to build an iOS, Android, or cross-platform app, we provide end-to-end development services, from concept to launch.`,
    whyUneedTitle: "Why you need",
    whyNeeded: `In today's mobile-first world, businesses need to engage with customers through their preferred platform—mobile. Mobile apps offer a more personalized and direct way to interact with users, streamline operations, and offer unique features like push notifications, geolocation, and offline functionality. With a well-designed mobile app, you can build brand loyalty, increase customer retention, and provide services more efficiently.`,
    benefits: [
      "Custom-built mobile applications tailored to your unique business needs and customer base.",
      "Seamless integration with existing systems and APIs for enhanced functionality and user experience.",
      "Focus on user-centric design and performance optimization to deliver a fast and intuitive mobile experience.",
      "Push notifications, real-time updates, and offline functionality to keep users engaged even when they’re not online.",
      "Comprehensive post-launch support and maintenance to ensure your app remains up-to-date and bug-free.",
      "Scalable architecture designed to grow with your user base, ensuring long-term reliability.",
      "Integration with third-party services like payment gateways, social media, and geolocation.",
      "Detailed analytics and reporting to track app performance, user engagement, and other key metrics.",
      "Flexible development options including native, hybrid, and cross-platform apps using React Native or Flutter.",
      "App Store and Google Play Store submission and optimization to maximize visibility and downloads.",
      "Focus on security, ensuring that user data is protected and compliant with industry standards.",
    ],
    tools: ["React Native", "Flutter", "Swift", "Kotlin"],
  },
];
const aboutUsContent = {
  about: `Welcome to ${CompanyName}, where innovation meets functionality. We are a dedicated team of developers and designers passionate about creating impactful digital solutions. Our diverse portfolio reflects our commitment to excellence and our ability to tackle complex challenges across various domains.`,
  projects: [
    {
      title: "Punjabi Cuisine",
      description:
        "Dive into the vibrant world of desi cuisine with Punjabi Cuisine, a user-centric platform that brings traditional Punjabi dishes to your table. Our project showcases our expertise in combining rich culinary experiences with modern web practices, ensuring a seamless and engaging customer experience.",
    },
    {
      title: "HealthTechSport",
      description:
        "Addressing the challenges of sedentary lifestyles in the digital age, HealthTechSport is our holistic approach to promoting physical well-being through technology. Our platform integrates fitness solutions with job creation opportunities, showcasing our commitment to enhancing quality of life and supporting economic development.",
    },
    {
      title: "Mughal Labs",
      description:
        "Revolutionizing healthcare diagnostics, Mughal Labs stands as a testament to our ability to merge technology with healthcare. With features like online reports and home sampling, our project emphasizes user convenience and transparency, reflecting our dedication to improving patient care.",
    },
    {
      title: "TaskTrek",
      description:
        "Streamline your project management with TaskTrek, our innovative solution for team collaboration and task tracking. Designed with modern technology and responsive design principles, TaskTrek highlights our focus on enhancing productivity and efficiency in dynamic work environments.",
    },
    {
      title: "HospitalRun",
      description:
        "Contributing to an open-source hospital management system, HospitalRun is tailored for resource-limited settings, providing essential tools for medical facilities to manage operations and patient care efficiently. This project demonstrates our commitment to making healthcare management accessible and effective, even in challenging environments.",
    },
    {
      title: "Hotel Tourist Inn",
      description:
        "Experience a virtual stay at Hotel Tourist Inn with our immersive and interactive platform. By showcasing the hotel's offerings through engaging design and intuitive navigation, this project underscores our ability to create compelling digital experiences that enhance user engagement and brand presence.",
    },
  ],
  closing: `At ${CompanyName}, we leverage a modern tech stack and innovative design principles to build solutions that not only meet but exceed client expectations. Our projects reflect our dedication to quality, user experience, and technological excellence. We are excited to explore new opportunities and collaborate on projects that drive meaningful impact. Thank you for visiting us. We invite you to explore our work and connect with us to discuss how we can bring your vision to life.`,
};

export default aboutUsContent;
