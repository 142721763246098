import "./App.css";
import Home from "./pages/Home";
import AppRoutes from "./router/AppRouter";

function App() {
  // return <Home />;
  return <AppRoutes />;
}

export default App;
