import React from "react";
import "./project.css";
import Img from "../../assets/ProgrammerPicProject.jpg";
import Card from "../../components/projectCard";

function Project() {
  return (
    <>
      <section className="flex project-page">
        <div className="hero">
          <img src={Img} alt="alt" />
          <h2 className="title secondaryColor primaryFont">Our Projects </h2>
        </div>
        <div className="whySection flex">
          <h2 className="h2 primaryFont secondaryColor">Why Asrnova?</h2>
          <p className="secondaryFont primaryColor secondaryFont">
            We are asrnova, a web development agency. Our name is a fusion of
            "bytes," the fundamental units of digital information, and "smith,"
            a skilled craftsman. We pride ourselves on being architects of the
            digital world. Our team of experts transforms raw data into elegant,
            functional, and user-centric web solutions. asrnova is more than
            just a name; it's a promise of delivering exceptional digital
            experiences.
          </p>
        </div>
        <div className="cardContainer flex">
          <h1 className="h2 primaryFont secondaryColor">Our Projects</h1>
          <Card />
        </div>
      </section>
    </>
  );
}

export default Project;
