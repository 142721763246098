import React from "react";
import "./ServiceScreenNav.css";
import { services } from "../../../Seed";
import { useNavigate } from "react-router-dom";

export const ServiceScreenNav = () => {
  const navigate = useNavigate();

  const formatTitleForURL = (title) => {
    return title.toLowerCase().replace(/\s+/g, "-");
  };

  const handleNavigation = (segment) => {
    const formattedSegment = formatTitleForURL(segment);
    navigate(`/services/${formattedSegment}`); // Navigate to the correct URL
  };

  return (
    <div className="serviceoptionslink">
      {services.map((service, index) => (
        <h5
          key={index}
          onClick={() => handleNavigation(service.title)}
          className="service-title"
        >
          {service.title}
        </h5>
      ))}
    </div>
  );
};
