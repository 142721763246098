import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./nav.css";
import { pages } from "../../Seed";
import { ServiceScreenNav } from "./serviceScreen";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [serviceOpen, setServiceOpen] = useState(false);
  const [serviceMobileOpen, setServiceMobileOpen] = useState(false); // For mobile dropdown

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleServiceEnter = () => {
    setServiceOpen(true);
  };

  const handleServiceLeave = () => {
    setServiceOpen(false);
  };

  const handleServiceToggleMobile = () => {
    setServiceMobileOpen(!serviceMobileOpen); // Toggle mobile service menu
  };

  return (
    <AppBar position="static" className="nav">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <img src={logo} alt="ByteSmith" className="logo" />
          </Link>

          {/* For mobile menu */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon className="menuIcon" />
            </IconButton>
            <Menu
              className="menu"
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages[0].map((pageName, index) =>
                pageName === "Services" ? (
                  <Box key={index}>
                    <MenuItem onClick={handleServiceToggleMobile}>
                      <Typography textAlign="center">{pageName}</Typography>
                    </MenuItem>
                    {serviceMobileOpen && (
                      <Box
                        sx={{
                          padding: "10px",
                          backgroundColor: "white",
                          border: "1px solid #aaa",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        }}
                      >
                        <ServiceScreenNav />
                      </Box>
                    )}
                  </Box>
                ) : (
                  <MenuItem key={index} onClick={handleCloseNavMenu}>
                    <Link
                      to={pages[1][index]}
                      className="nav-link secondaryColor link m-10"
                    >
                      <Typography textAlign="center">{pageName}</Typography>
                    </Link>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>

          {/* For desktop menu */}
          <Box
            className="NavBarPageLinkSingle"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          >
            {pages[0].map((pageName, index) =>
              pageName === "Services" ? (
                <Box
                  key={index}
                  className="link secondaryColor nav-link"
                  onMouseEnter={handleServiceEnter}
                  onMouseLeave={handleServiceLeave}
                  sx={{ position: "relative" }} // Positioning for dropdown
                >
                  <Link className="ServiceLinkInNav secondaryColor">
                    {pageName}
                  </Link>
                  {serviceOpen && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        zIndex: 10,
                        backgroundColor: "white",
                        border: "1px solid #aaa",
                        borderRadius: "15px",
                        boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                        padding: "10px",
                        minWidth: "150px",
                      }}
                    >
                      <ServiceScreenNav />
                    </Box>
                  )}
                </Box>
              ) : (
                <Link
                  to={pages[1][index]}
                  key={index}
                  onClick={handleCloseNavMenu}
                  className="link secondaryColor nav-link"
                >
                  {pageName}
                </Link>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar;
