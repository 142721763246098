import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "./footer.css";
import Insta from "../../assets/icons/insta.png";
import LinkedIn from "../../assets/icons/linkedin.png";
import { footerpages } from "../../Seed";

const Footer = () => {
  return (
    <div className="footer">
      <div className="curve"></div>
      <footer>
        <Box className="footer-section" component="div">
          <div className="icon-wrapper-footer"></div>
          <div className="align-center">
            <Box className="column site-map">
              <h2 className="secondaryColor footer-h2">Site Map</h2>
              <ul className="column primaryColor">
                {footerpages[0].map((pageName, index) => (
                  <li>
                    <Link
                      to={footerpages[1][index]}
                      key={index}
                      className="footer-link"
                    >
                      {pageName}
                    </Link>
                  </li>
                ))}
              </ul>
            </Box>
            <Box className="column Contact">
              <h2 className="secondaryColor footer-h2">Contact Us</h2>
              <ul className="column ">
                <li className="primaryColor">
                  83 Muslim Colony, National Town, Lahore
                </li>
                <li className="footer-link ">
                  <a href="tel:04237157284">(042) 37157284</a>
                </li>
                <li className="footer-link ">
                  <a href="mailto:hassanmal8i69@gmail.com">info@asrnova.com</a>
                </li>
              </ul>
            </Box>
            <Box className="column Socials">
              <h2 className="secondaryColor footer-h2">Socials</h2>
              <ul className="social-icons flex">
                <li>
                  <a
                    href="https://www.linkedin.com/company/104894623/admin/inbox/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link "
                  >
                    <img src={LinkedIn} alt="icon" className="footer-icon" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.instagram.com/_asrnova/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link "
                  >
                    <img src={Insta} alt="icon" className="footer-icon" />
                  </a>
                </li>
              </ul>
            </Box>
          </div>
        </Box>
        <Box component="div" className="patent">
          <p>Copyright © asrnova.com. All rights reserved.</p>
        </Box>
      </footer>
    </div>
  );
};

export default Footer;
