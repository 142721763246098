import React from "react";
import { useParams } from "react-router-dom";
import { servicesPage } from "../../Seed"; // Assuming services is an array
import "./service.css";
export const Service = () => {
  const { segment } = useParams(); // Extract the URL segment

  const service = servicesPage.find(
    (service) => service.title.toLowerCase().replace(/\s+/g, "-") === segment
  );

  if (!service) {
    return console.log("serilca");
  }

  return (
    <>
      <section className="ServiceSectionPortfolio">
        <div className="heroBg">
          <div className="textContent">
            <h1 className="title primaryFont clr-white center">
              {service.title}
            </h1>
          </div>
        </div>
        <div className="serviceSectionMainContent column">
          <div className="descriptionintroService">
            <h1 className="title secondaryColor primaryFont">
              Service Description
            </h1>
            <p className="secondaryFont primaryColor secondaryFont ServiceDescription">
              {" "}
              {service.description}{" "}
            </p>
          </div>
          <div className="benefitsServiceContainer">
            <h1 className="title primaryColor secondaryFont">Benefits</h1>
            <div className="benefitsContainer">
              {service.benefits.length > 0 ? (
                <ul>
                  {service.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              ) : (
                <p>No benefits listed for this service.</p>
              )}
            </div>
          </div>
          <div className="parentContainerForWhyNeededTechStack">
            <div className="whyNeededContainer">
              <h1 className="fs-32 secondaryColor primaryFont">
                Why {service.title}?
              </h1>
              <p>{service.whyNeeded}</p>
            </div>
            <div className="techStackContainer">
              <h1 className=" secondaryColor primaryFont">Tech Stack</h1>
              <ul>
                {service.tools.map((tech, index) => (
                  <li key={index}>{tech}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
